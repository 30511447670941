import * as React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { centerize, fixRatio, alignVertical, Colors } from '../styles'
import { Layout } from '../components/Layout'

export const query = graphql`
	query {
		microcmsCompany {
			kv {
				url
			}
			table {
				title
				text
			}
		}
		microcmsMeta {
			title
			description
		}
	}
`

const Index = ({
	data: { microcmsCompany, microcmsMeta },
}: {
	[key: string]: any
}) => {
	const [data] = React.useState(microcmsCompany)
	return (
		<Layout
			helmet={{
				title: microcmsMeta.title,
				description: microcmsMeta.description,
			}}
			children={
				<>
					{data.kv.url && <StyledImage image={data.kv.url} />}
					<StyledDiv>
						{/* <StyledTitle>
							<h3>Company Profile</h3>
							<span>会社概要</span>
						</StyledTitle> */}
						<StyledTable>
							{data.table.map((item: any, index: number) => (
								<tr key={`table-item-${index}`}>
									<th>{item.title}</th>
									<td>{item.text}</td>
								</tr>
							))}
						</StyledTable>
					</StyledDiv>
				</>
			}
		/>
	)
}

export default Index

const StyledImage = styled.div<{ image: string }>`
	background: url(${(props) => props.image}) center / contain no-repeat;
	max-height: 360px;
	${centerize};
	${fixRatio(1)};
`

const StyledDiv = styled.div`
	max-width: 980px;
	margin: 60px auto 0;
	padding: 0px 40px;
`

const StyledTitle = styled.div`
	margin-bottom: 60px;
	${alignVertical};
	> h3 {
		display: inline-block;
		padding-bottom: 8px;
		position: relative;
		color: ${Colors.text};
		border-bottom: 2px solid ${Colors.border};
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0.4rem;
	}
	> span {
		text-align: center;
		padding-top: 12px;
	}
`

const StyledTable = styled.table`
	padding: 0 0 60px;
	margin: 0 auto;
	> tr {
		padding: 12px;
		border-bottom: 1px solid #e5e5e5;
		text-align: center;
	}
	th {
		padding: 12px 12px 12px 48px;
		text-align: right;
		vertical-align: top;
		font-weight: bold;
	}
	td {
		padding: 12px 48px;
		text-align: left;
		white-space: pre-wrap;
	}
`
